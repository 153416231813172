import styled from 'styled-components'

export const CardContainer = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  min-height: 200px;
  border: 1px solid ${({gray}) => (gray ? '#565452' : '#E4A436')};
  border-radius: 4px;
  margin-bottom: 20px;
  margin: 20px 40px;
  @media (max-width: 400px) {
    margin: 20px 0px;
  }
`

export const CardHeader = styled.h2`
  background-color: ${({gray}) => (gray ? '#565452' : '#E4A436')};
  color: ${({gray}) => (gray ? '#FFFFFF' : '#201B1B')};
  display:flex;
  width: auto;
  align-items:center;
  justify-content: center;
  padding: 13px;
  font-family: Hind Madurai;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
`
export const CardBody = styled.div`
  padding: 10px 45px;
`
export const SemiBoldWrapper = styled.span`
 font-weight: 600;
`
export const CardBodyContent = styled.p`
  text-align: left;
  padding: 2px;
  font-family: Hind Madurai;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
`

export const ContactNowButton = styled.button`
  width: 155.88px;
  height: 42px;
  font-family: Hind Madurai;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  align-self: center;
  color: #000000;
  background: #E4A436;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 10px;
`
