export const CardObjOne = {
  gray: true,
  headerContent: 'What You Do',
}

export const CardObjTwo = {
  gray: true,
  headerContent: 'What We Do',
}

export const CardObjThree = {
  gray: false,
  headerContent: 'The Sale',
}
