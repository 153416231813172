import React from 'react'
import  { HeaderContainer,StyledNewIcon, LogosWrapper, ContactWrapper } from './headerElements'
// import {FaPhoneAlt as PhoneIcon} from 'react-icons/fa'
// import {IoMail as EmailIcon} from 'react-icons/io5'


const Header = ()=> {
  return (
    <>
      <HeaderContainer>
        <LogosWrapper to='/'>
          <StyledNewIcon />
        </LogosWrapper>
        <ContactWrapper>
        </ContactWrapper>
      </HeaderContainer>
    </>
  )
}

export default Header
