import styled from 'styled-components'


export const ListingContainer = styled.div`
 display: flex;
 margin: 20px;
 height: min-content;
 border: 1px solid rgb(204 204 204);
 border-radius: 3px;
 flex-direction: column;

 @media (max-width: 600px) {
   align-items: center;
 }

 @media (max-width: 400px) {
   align-items: center;
 }
`
export const ImgWrapper = styled.div`
 display: flex;
 flex-wrap: wrap;
 width: 300px;
 min-width:300px;
 height: 300px;
 img {
   border-radius: 2px 2px 0px 0px;
   min-width: 300px;
   width:300px;
   height:300px;
  }
 @media (max-width: 600px) {
   min-width: 300px;
   width:300px;
   height:300px;
   align-items: center;
   justify-content: center;
 }
`
export const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
padding: 5px;
min-height: 300px;
width: 300px;
 @media (max-width: 600px) {
   height: auto;
   width:300px;
 }
`
export const TopInfo = styled.div`
text-align: left;
`


export const Title = styled.h2`
height: auto;
margin-bottom: 10px;

font-family: Hind Madurai;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
text-align: left;
text-transform: uppercase;
hyphens: auto;

color: black;
@media (max-width: 780px) {
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 520px) {
  font-size: 14px;
  line-height: 16px;
  height: auto;
}
`
export const Price = styled.h2`
height: auto;
margin-bottom: 15px;

font-family: Hind Madurai;
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 24px;
text-align: left;
text-transform: uppercase;
font-variant: all-small-caps;

color: black;
`

// pass a prop for the lineClamp
export const Description = styled.div`
  text-align: left;
  font-family: Hind Madurai;
  font-weight: 300;
  max-width: 680px;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0px;
  /* cut to 5 lines */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.dropDown ? 'none' : 8};
  overflow: hidden;

  p:nth-child(10) {
    padding-bottom: 0px;
  }
  @media (max-width: 600px) {
  }
`

export const ExternalLink = styled.a`
width: 155.88px;
height: 42px;
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;
background: #E4A436;
border: 2px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 7px;
text-decoration: underline;
color: black;
padding-top: 5px;
margin: 5px 0px;
align-self: center;
:visited {
    color: black;
}
`
