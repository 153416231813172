import { useState } from 'react'
import { useQuery, gql} from '@apollo/client'
import styled from 'styled-components'
import ModalContact from '../components/modalcontact'
import Header from '../components/header'
import Nav from '../components/nav'
import imageUrlBuilder from '@sanity/image-url'


export const GalleryContainer = styled.div`
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 height: min-content;
 /*border: 1px solid rgb(204 204 204);*/
 border-radius: 3px;
 max-width: 900px;

 @media (max-width: 880px) {
   max-width: calc(100vw - 5px);
   margin: 5px;
 }

 @media (max-width: 400px) {
   align-items: center;
 }
`
export const SoldTitle = styled.h1`
  margin-top: 36px;
  font-family: Hind Madurai;
`
export const ImgWrapper = styled.div`
 display: flex;
 flex-wrap: wrap;
 height: 300px;
 img {
   border-radius: 2px 2px 0px 0px;
   min-width: 300px;
   width:300px;
   height:300px;
   padding: 14px;
 }
 @media (max-width: 880px) {
   /*min-width: 300px;*/
   /*max-width: calc((100vw / 3) - 20px);*/
   max-height: calc((100vw / 3) - 20px);
   align-items: center;
   justify-content: center;
   img {
     max-height: calc((100vw / 3) - 20px);
     border-radius: 2px 2px 0px 0px;
     min-width: 100%;
     padding: 2px;
    }
   }
 }
`
const GET_LISTINGS = gql`
{
  allGallery{
    _id
    title
    image {
 	    asset{
        url
      }
    }
  }
}
`
const sanityClient = require('@sanity/client')


const client = sanityClient({
  projectId: 'dfb2hllj',
  dataset: 'production',
  apiVersion: `1`, // use current UTC date - see "specifying API version"!
  //token: 'sanity-auth-token', // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
})

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const Gallery = ()=> {
  const [isOpen, setIsOpen] = useState(false)
  const { loading, error, data} = useQuery(GET_LISTINGS);
  console.log('props')
  console.log(data)

  if (loading) {
    console.log('loading')
  }
  if (error) {
    console.log(data)
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal(){
    setIsOpen(false);
  }
    return(
    <div className="Listings">
      <ModalContact style={{zIndex: 3}} openModal={openModal} closeModal={closeModal} isOpen={isOpen} />
      <Header />
      <Nav openModal={openModal} />
      <SoldTitle>Gallery</SoldTitle>
      <GalleryContainer>
      { loading || error ?
        (<p></p>)
        :
        (
            
            data.allGallery.map((item, key)=> (
                <ImgWrapper
                  img={item.image.asset.url}
                  key={item.title}
                >
                  <img src={urlFor(item.image).width(800).fit('fill').url()} alt={(item.title) ? item.title : `sold-item-${key}`} />
                </ImgWrapper>

            ))
        )
        }

      </GalleryContainer>
        </div>
    )
}

export default Gallery
