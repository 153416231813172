import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages/Home'
import Listings from './pages/Listings'
import Gallery from './pages/Gallery'

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/inventory' component={ Listings } />
        <Route path='/gallery' component={ Gallery } />
        <Route path='/' component={ Home } />
      </Switch>
    </Router>
  );
}

export default App;
