import{SemiBoldWrapper as SB, ContactNowButton, CardContainer, CardHeader, CardBody, CardBodyContent} from './CardElements'

/*
const CardElementOne = ({ gray, headerContent})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
             <CardBodyContent>
               <SB>Contact through site</SB>
             </CardBodyContent>
       </CardBody>
      </CardContainer>
  ) 
} 
*/

const CardElementTwo = ({ gray, headerContent})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
         <CardBodyContent>Take photos, make ads, and handle <SB> all prospects</SB></CardBodyContent>
       </CardBody>
      </CardContainer>
  ) 
} 

const CardElementThree = ({ gray, headerContent, openModal})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
             <CardBodyContent>
               <SB>Secure the transaction</SB> for <SB>buyer</SB> and <SB> seller</SB> 
             </CardBodyContent>
       </CardBody>
       <ContactNowButton onClick={openModal}>Get in Touch</ContactNowButton>
      </CardContainer>
  ) 
} 
export {CardElementTwo, CardElementThree}
