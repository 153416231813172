import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const NavWrapper = styled.nav`
  width: max(80vw, 400px);
  background: #E4A436;
  height: 50px;
  z-index: 1;
  margin-top: -25px;
  margin-bottom: -25px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
  }
`

export const StyledLink = styled(Link)`
  color: white;
  font-family: Hind Madurai;
  font-weight: 500;
  min-width: 150px;
  display: inline-block;
  font-size: 18px;
  line-height: 25px;
  margin: 0px 10px;
  text-transform: uppercase;
  text-decoration: none;
  @media (max-width: 850px) {
    min-width: 100px;
  }
  @media (max-width: 550px) {
    min-width: 65px;
    font-size: 16px;
    margin: 0px 5px;

  }
`
