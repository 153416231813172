import React, {useState} from 'react'
import {ListingContainer, ImgWrapper, InfoWrapper, Title, Price, Description, ExternalLink, TopInfo} from './listingElements'
import imageUrlBuilder from '@sanity/image-url'
const sanityClient = require('@sanity/client')
const BlockContent = require('@sanity/block-content-to-react')


const client = sanityClient({
  projectId: 'dfb2hllj',
  dataset: 'production',
  apiVersion: `1`, // use current UTC date - see "specifying API version"!
  //token: 'sanity-auth-token', // or leave blank for unauthenticated usage
  useCdn: true, // `false` if you want to ensure fresh data
})
const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

// little util to return price with $3000 vs 3000
const cleanPrice = (price)=> {
  let cleanedPrice = price
  if (cleanedPrice.includes('.')) {
    cleanedPrice = cleanedPrice.split('.')[0] 
  }
  if (price[0] !== '$') {
    cleanedPrice = '$'+ cleanedPrice
  }
  console.log(cleanedPrice)
  if (cleanedPrice.length > 4) {
    //['$','1', '0' , '0' , '0']
    //insert a comma
    cleanedPrice = cleanedPrice.slice(0, cleanedPrice.length -3) + ',' + cleanedPrice.slice(cleanedPrice.length-3, cleanedPrice.length)
  }
  return cleanedPrice
}

const Listing= ({ title, img, description, price, link})=> {
    const [dropDown, setDropDown] = useState(false);
    return (
      <ListingContainer>
        {img && <ImgWrapper>
            <img src={urlFor(img).width(300).height(300).fit('fill').url()} alt={title} />
        {/*
            <img src={img} alt={title} />
        */}
        </ImgWrapper>}
        <InfoWrapper>
            <TopInfo>
                <Price>{cleanPrice(price)}</Price>
                <Title>{title}</Title>
                <Description dropDown={dropDown}>
 <BlockContent
    // Array of portable text blocks
   blocks={description}                  />
                </Description>
            </TopInfo>
            {/* never any link set */}
            <ExternalLink
            target="_blank" 
            rel="noreferrer"
            onClick={()=> setDropDown(!dropDown)}
            href={link}>
                Full Posting
            </ExternalLink>
        </InfoWrapper>
      </ListingContainer>
    )
  }
  
  export default Listing
