import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {ReactComponent as Title} from '../../pics/title.svg'
import {ReactComponent as Icon} from '../../pics/icon.svg'
import {ReactComponent as NewIcon} from '../../pics/newIcon.svg'

export const HeaderContainer = styled.header`
background-color: #F1F2F3;
display: flex;
width: 100vw;
/*height: 123px;*/
padding-bottom: 25px;
align-items: center;
@media (max-width: 1000px) {
 flex-direction: column;
  /* height: 200px;*/
 }
`
export const LogosWrapper = styled(Link)`
text-decoration: none;
display:flex;
align-items:center;
@media (max-width: 1000px) {
  justify-content: center;
}

`
export const StyledIcon = styled(Icon)`
width: min(90px, 20%);
max-height: 77px;
`
export const StyledNewIcon = styled(NewIcon)`
width: 100%;
margin-top: -25px;
margin-bottom: -25px;
`
export const StyledTitle = styled(Title)`
width: min(575px, 80%);
max-height: 66px;
`
export const ContactWrapper = styled.div`
flex: auto;
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: center;
font-family: Hind Madurai;
font-weight: 500;
`
export const PhoneContact = styled.div`
display: flex;
align-items: center;
width: min(100%, 250px);
width: min(100%, 300px);
font-size: 22px;
 a {
   color: black;
   text-decoration: none;
 }
 a:visited {
   color: black;
   text-decoration: none;
 }
`
export const EmailContact = styled.div`
display: flex;
align-items: center;
width: min(100%, 250px);
width: min(100%, 300px);
font-size: 22px;
 a {
   color: black;
   text-decoration: none;
 }
 a:visited {
   color: black;
   text-decoration: none;
 }
`
