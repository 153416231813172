import React from 'react'
import { NavWrapper, StyledLink } from './navElements'

const Nav = ({openModal})=> {
  return(
    <NavWrapper>
      <StyledLink to='/'>Home</StyledLink>
      <StyledLink to='/gallery'>Gallery</StyledLink>
      <StyledLink to='/inventory'>Inventory</StyledLink>
      <StyledLink onClick={openModal}>Sell Equipment</StyledLink>
    </NavWrapper>
  )
}
export default Nav
