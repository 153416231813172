import { useState } from 'react'
import { useQuery, gql} from '@apollo/client'
import styled from 'styled-components'
import ModalContact from '../components/modalcontact'
import Header from '../components/header'
import Nav from '../components/nav'
import Listing from '../components/listing'

const ListingsWrapper = styled.div`
margin-top: 25px;
display: flex;
flex-wrap: wrap;
justify-content: center;
/*justify-content: space-between;*/
/*justify-content: space-around;*/
`

const GET_LISTINGS = gql`
{
  allListing{
    _id
    title
    link
    image {
 	    asset{
        url
      }
    }
    descriptionRaw
    price
  }
}
`
const Listings = ()=> {
  const [isOpen, setIsOpen] = useState(false)
  const { loading, error, data} = useQuery(GET_LISTINGS);

  if (loading) {
    console.log('loading')
  }
  if (error) {
    console.log(data)
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal(){
    setIsOpen(false);
  }
    return(
    <div className="Listings">
      <ModalContact style={{zIndex: 3}} openModal={openModal} closeModal={closeModal} isOpen={isOpen} />
      <Header />
      <Nav openModal={openModal} />
      <ListingsWrapper>
      { loading || error ?
        (<p>Listings</p>)
        :
        (
            
            data.allListing.map((item, key)=> (
                <Listing 
                title={item.title}
                img={item?.image?.asset?.url}
                description={item.descriptionRaw}
                link={item.link}
                price={item.price}
                />
            ))

            
        )
        }

      </ListingsWrapper>
        </div>
    )
}

export default Listings
