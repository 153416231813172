import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
//import './ContactUs.css';

const FormLabel = styled.label`
  font-family: Hind Madurai;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`
const NameWrapper = styled.div`
display: flex;
flex-direction: column;
width: 45%;
@media (max-width: 400px) {
  width: auto;
}
`
const StyledInput = styled.input`
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
padding: 5px;
`
const StyledTextArea = styled.textarea`
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
padding: 5px;

`

const MessageWrapper = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 16px;

`
const NameAndEmail = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 8px;
@media (max-width: 400px) {
  flex-direction: column;
}
`

const SubmitButton = styled.input`
background: #18A0FB;
border: 2px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 7px;
width: 165px;
height: 42px;
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #FFFFFF;
margin-bottom: 8px;

`

export default function ContactUs({setSuccess}) {
  const [honey, setHoney] = useState(false)

  function sendEmail(e) {
    e.preventDefault();
    console.log(e.target)

    const service = process.env.REACT_APP_EMAIL_SERVICE_ID
    const template = process.env.REACT_APP_EMAIL_TEMPLATE_ID
    const user = process.env.REACT_APP_EMAIL_USER_ID
    if (!honey) {
    emailjs.sendForm(service, template, e.target, user)
      .then((result) => {
          console.log(result.text);
          setSuccess(true)
      }, (error) => {
          console.log(error.text);
      });
    } else {
      window.location.assign("https://www.youtube.com/watch?v=ADj2jDqT4uY"); 

    }
  }

  return (
    <form className="contact-form" onSubmit={sendEmail}>
      <input type="hidden" name="realImportant"  onChange={()=>setHoney(true)} />
      <NameAndEmail>
        <NameWrapper>
          <FormLabel>Name</FormLabel>
          <StyledInput placeholder={'Name'} type="text" name="user_name" />
        </NameWrapper>
        <NameWrapper>
          <FormLabel>Email</FormLabel>
          <StyledInput placeholder={'Email'} type="email" name="user_email" />
        </NameWrapper>
      </NameAndEmail>
      <MessageWrapper>
        <FormLabel>Message</FormLabel>
        <StyledTextArea placeholder={'Message'} style={{minHeight: '72px'}} name="message" />
      </MessageWrapper>
      <SubmitButton type="submit" value="Send" />
    </form>
  );
}
