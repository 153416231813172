import React from 'react'
// import {FaPhoneAlt as PhoneIcon} from 'react-icons/fa'
// import {IoMail as EmailIcon} from 'react-icons/io5'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  height: 150px;
  background: #4D4D53;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ContactWrapper = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin-left: 16px;
  font-family: Hind Madurai;
  font-weight: 500;
`

/* const PhoneContact = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  a {
   color: white;
   text-decoration: none;
  }
  a:visited {
   color: white;
   text-decoration: none;
  }
  margin-bottom: 16px;
`
const EmailContact = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  a {
   color: white;
   text-decoration: none;
  }
  a:visited {
   color: white;
   text-decoration: none;
  }
`
*/
const GoldenSpan = styled.span`
  color: #E4A436;
`

const Footer = ()=> {
  return (
    <FooterContainer>
      <ContactWrapper>
      </ContactWrapper>
<div style={{ marginBottom: '16px', marginRight:'16px', textAlign: 'right'}}>
<span style={{color: 'white'}}>All rights reserved <GoldenSpan>CALIFORNIAEQUIPMENT.COM</GoldenSpan></span>
      </div>
    </FooterContainer>
  )
}

export default Footer
